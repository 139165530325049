import React from 'react';
import clsx from 'clsx';
import {Link} from 'gatsby';
import {Event} from '../../../GAClickEvent';

import DesignProOne from '../../../../assets/images/landing/design-pro/design-pro-1.svg';
import DesignProTwo from '../../../../assets/images/landing/design-pro/design-pro-2.svg';
import DesignProThree from '../../../../assets/images/landing/design-pro/design-pro-3.svg';
import DesignProFour from '../../../../assets/images/landing/design-pro/design-pro-4.svg';

import * as classes from './Partnership.module.css';

const PartnershipSection = () => (
  <div className={classes.wrapper}>
    <div className={clsx(classes.container, 'flex-column')}>
      <h1 className={clsx(classes.sectionTitle, 'mb-3')}>Business Problem</h1>
      <p className={clsx(classes.description, 'mb-3')}>
        DesignPro was a Figma plugin created to streamline the workflows of design teams.Designers
        often grapple with scattered tasks, notes, and communications across various channels. This
        fragmentation poses a significant hurdle to maintaining a focused and organized design
        process. While the plugin they had created was able to streamline these workflows, the
        DesignPro team was now looking to leverage AI to automatically transform those comments,
        notes, documents, messages and video calls instantly into actionable tasks.
      </p>
    </div>
    <div className={clsx(classes.container, 'flex-column')}>
      <h1 className={clsx(classes.sectionTitle, 'mb-3')}>DesignPro and Tintash Partnership</h1>
      <p className={clsx(classes.description, 'mb-3')}>
        Tintash was engaged as the development partner to integrate AI into the existing DesignPro
        platform, and to efficiently prototype and test their new ideas that would make task
        organization 6-8 times faster.
      </p>
    </div>
    <div className={clsx(classes.container, 'flex-column-reverse flex-lg-row')}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={DesignProOne} alt="design pro" />
      </div>
      <div className={clsx(classes.textContainer, 'pl-lg-5')}>
        <h2 className={classes.title}>The Agile Process</h2>
        <p className={clsx(classes.description)}>
          Our agile approach involved exploring new product ideas in sprints, creating prototypes
          bi-weekly for real customer testing, and rapidly iterating based on feedback. This allowed
          for continuous experimentation with target customers. After finding a product-market fit,
          we shifted to scaling and refining products, always adaptable to feedback and evolving
          requirements.
        </p>
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column flex-lg-row')}>
      <div className={clsx(classes.textContainer, 'pr-lg-5')}>
        <h2 className={classes.title}> LLM Integration for Task Synthesis</h2>
        <p className={clsx(classes.description)}>
          We developed a design workflow management system using OpenAI's Large Language Models to
          transform unstructured client feedback into structured tasks, mimicking a Jira-like
          environment. Our system automates task prioritization, grouping, and assignment on a
          Kanban board, significantly reducing task setup time. Leveraging Python's LangChain with
          ChatGPT, we enhanced productivity and user satisfaction, leading to a swift expansion of
          DesignPro’s clientele.
        </p>
      </div>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={DesignProTwo} alt="design pro" />
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column-reverse flex-lg-row')}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={DesignProThree} alt="design pro" />
      </div>
      <div className={clsx(classes.textContainer, 'pl-lg-5')}>
        <h2 className={classes.title}> AI-Powered Design Feedback Generation</h2>
        <p className={classes.description}>
          We introduced an AI-driven design reviewer for DesignPro, capable of autonomously
          evaluating UI designs in Figma and offering actionable critiques, allowing designers to
          focus on creativity. Utilizing LLMs trained on established design principles and advanced
          AI techniques, this feature delivers high-quality feedback, significantly enhancing
          DesignPro's appeal to customers and investors.
        </p>
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column flex-lg-row')}>
      <div className={clsx(classes.textContainer, 'pr-lg-5')}>
        <h2 className={classes.title}>Extensive and Secure Tool Integration</h2>

        <p className={clsx(classes.description)}>
          Our dedicated integration team continues to incorporate tools such as Figma, Slack, Zoom,
          Loom, and Google Docs into DesignPro. This adaptability is key to scaling the product and
          reaching a broader audience. The team ensures a seamless integration of these tools
          together with robust data security to enhance the user experience.
        </p>
      </div>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={DesignProFour} alt="design pro" />
      </div>
    </div>
    <div className={clsx(classes.buttonContainerMain)}>
      <div className={classes.buttonContainer}>
        <Link
          onClick={() =>
            Event('Case Study', 'Case Study Button is clicked', 'Design Pro Landing Page')
          }
          to="/portfolio/design-pro"
        >
          <button type="button" className={classes.button}>
            Read Full Case Study
          </button>
        </Link>
      </div>

      <div className={classes.buttonContainer}>
        <Link
          onClick={() =>
            Event('Portfolio', 'Portfolio button is clicked', 'Design Pro Landing Page')
          }
          to="/portfolio"
        >
          <button type="button" className={classes.button2}>
            Explore Our Portfolio
          </button>
        </Link>
      </div>
    </div>
  </div>
);

export default PartnershipSection;
