// extracted by mini-css-extract-plugin
export var button = "Partnership-module--button--wc2u7";
export var button2 = "Partnership-module--button2---djHK";
export var buttonContainer = "Partnership-module--buttonContainer--sJMx4";
export var buttonContainerMain = "Partnership-module--buttonContainerMain--Rwyzb";
export var container = "Partnership-module--container--AUGH7";
export var description = "Partnership-module--description--U-bDr";
export var image = "Partnership-module--image--4ztFL";
export var imageContainer = "Partnership-module--imageContainer--fY8UB";
export var sectionTitle = "Partnership-module--sectionTitle--v8TQO";
export var textContainer = "Partnership-module--textContainer--wWVpM";
export var title = "Partnership-module--title--BiO7S";
export var wrapper = "Partnership-module--wrapper--mh3nb";