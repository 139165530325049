import React from 'react';
import Helmet from 'react-helmet';

import LandingHeader from '../../../components/Landing/Section/Header';
import HeroSection from '../../../components/Landing/Section/Hero';
import TestimonialSection from '../../../components/Landing/Section/Testimonial';
import '../../../assets/css/landing.css';

import DesignProImage from '../../../assets/images/landing/design-pro/designpro-section-image.svg';
import AboutSection from '../../../components/Landing/Section/About';
import ClientLogosSection from '../../../components/Landing/Section/ClientLogos/lndex';
import LandingFooter from '../../../components/Landing/Section/Footer';
import PartnershipSection from '../../../components/Landing/Section/PartnerShipThree';

const themeColor = '#1AA5F8';

const Hero = {
  title: (
    <div>
      <strong>Using AI to Simplify Task Management.</strong>
    </div>
  ),
  subTitle: 'Streamlining workflows in Figma and leveraging AI to boost designer productivity.',
  bannerSrc: require('../../../assets/images/landing/design-pro/design-pro-banner.svg').default,
  logoSrc: require('../../../assets/images/landing/design-pro/design-pro-logo.svg').default,
};

const Testimonial = {
  titleLogoSrc: require('../../../assets/images/landing/design-pro/designpro-x-tintash.svg')
    .default,
  details: [
    <div>
      DesignPro's new Figma Plugin gained{' '}
      <span className="text-blue">1000+ users organically within 7 days of launch.</span>
    </div>,
    <div>
      AI-based review of <span className="text-blue">800 Figma designs</span> provided valuable
      feedback based on design principles (Norman's, IDEO, etc.).
    </div>,
    <div>
      Design leaders at companies like <span className="text-blue">Cisco and Miro</span> expressed
      interest in using DesignPro.
    </div>,
  ],
  testimonial: {
    quote: (
      <div>
        I have worked with some of the best AI talent in the world at companies like Siri and Apple.
        When it came to building an AI team for my own startup DesignPro I relied on Tintash to find
        me talent with excellent research, engineering and communication skills.
        <br />
        <br />I am beyond impressed with their talent pool and team’s ability to do exceptional work
        at an affordable price.
      </div>
    ),
    name: 'Mohammed Abdoolcarim',
    position: <div>Cofounder - DesignPro</div>,
    image: require('../../../assets/images/landing/design-pro/design-pro-client.svg').default,
  },
};

function Index() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Landing | Design Pro</title>
      </Helmet>
      <div className="landing-wrapper">
        <LandingHeader />
        <HeroSection
          title={Hero.title}
          subTitle={Hero.subTitle}
          bannerSrc={Hero.bannerSrc}
          logoSrc={Hero.logoSrc}
          type="enhanced"
        />
        <TestimonialSection
          titleLogoSrc={Testimonial.titleLogoSrc}
          details={Testimonial.details}
          testimonial={Testimonial.testimonial}
          themeColor={themeColor}
        />
        <img src={DesignProImage} alt="design pro" />
        <PartnershipSection />
        <AboutSection />
        <ClientLogosSection />
        <LandingFooter />
      </div>
    </>
  );
}

export default Index;
